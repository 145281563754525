import { LayerSettings } from "@app/core/interfaces/layer-settings.interface";
import { MapModes } from "@app/core/interfaces/map-modes.enum";
import { LatLngBoundsLiteral } from "leaflet";

export interface MapStateModel {
    bounds: LatLngBoundsLiteral
    zoom: number;
    center: [number, number],
    mode: MapModes,
    layers: LayerSettings[],
    highlight: GeoJSON.Geometry[];
    measureSettings: {
        advanced: boolean,
        snapping: boolean,
        multiple: boolean,
        angles: boolean,
        automatic: boolean
    }
}

export class PatchMapState {
    static readonly type = '[Map] PatchState';
    constructor(public payload: {
        bounds?: LatLngBoundsLiteral
        zoom?: number;
        center?: [number, number],
        mode?: MapModes.Identify
    }) {

    }
}

export class UpdateMapBounds {
    static readonly type = '[Map] UpdateBounds';
    constructor(public payload: { bounds: LatLngBoundsLiteral, triggerMap?: boolean, triggerClick?: [number, number] }) { }
}

export class UpdateMapCenter {
    static readonly type = '[Map] UpdateCenter';
    constructor(public payload: { center: [number, number], triggerMap?: boolean }) { }
}

export class UpdateMapZoom {
    static readonly type = '[Map] UpdateZoom';
    constructor(public payload: { zoom: number, triggerMap?: boolean }) { }
}

export class UpdateMapMode {
    static readonly type = '[Map] UpdateMapMode';
    constructor(public payload: { mode: MapModes }) { }
}

export class ToggleMapLayer {
    static readonly type = '[Map] ToggleLayer';
    constructor(public payload: { name: string, variation?: string | undefined }) { }
}

export class EnableMapLayer {
    static readonly type = '[Map] EnableLayer';
    constructor(public payload: { name: string }) { }
}

export class DisableMapLayer {
    static readonly type = '[Map] DisableLayer';
    constructor(public payload: { name: string }) { }
}

export class SetMeasureSettings {
    static readonly type = '[Map] SetMeasureSettings';
    constructor(public payload: { advanced?: boolean, snapping?: boolean }) { }
}

export class SetHighlight {
    static readonly type = '[Map] SetHighlight';
    constructor(public payload: { highlight: GeoJSON.Geometry[] }) { }
}

export class TriggerZoomOut {
    static readonly type = '[Map] TriggerZoomOut';
    constructor() { }
}

export class ResetMapLayers {
    static readonly type = '[Map] ResetLayers';
    constructor() { }
}