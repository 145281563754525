import { createNgModuleRef, Injectable, Injector } from '@angular/core';
import { from, switchMap } from 'rxjs';
import { CoreModule } from '../core.module';

@Injectable({
  providedIn: CoreModule
})
export class NotificationService {

  private snackbar;
  private dialog;

  constructor(
    private injector: Injector,
  ) {

  }

  public async getDialog() {
    if (this.dialog === undefined) {
      this.dialog = await this.loadDialog();
    }

    return this.dialog;
  }

  private async loadDialog() {
    const { MatDialogModule: MatDialogModule } = await import("@angular/material/dialog");
    const moduleRef = createNgModuleRef(MatDialogModule, this.injector);

    const { MatDialog: MatDialog } = await import("@angular/material/dialog");
    const dialog = moduleRef.injector.get(MatDialog);

    return dialog;
  }

  public async  getSnackbar() {
    if (this.snackbar === undefined) {
      this.snackbar = await this.loadSnackbar();
    }

    return this.snackbar;
  }

  private async loadSnackbar() {
    const { MatSnackBarModule: MatSnackBarModule } = await import("@angular/material/snack-bar");
    const moduleRef = createNgModuleRef(MatSnackBarModule, this.injector);

    const { MatSnackBar: MatSnackBar } = await import("@angular/material/snack-bar");
    const snackbar = moduleRef.injector.get(MatSnackBar)

    return snackbar;
  }

  public showWarning(message: string) {
    return from(this.getSnackbar()).pipe(
      switchMap((snackbar) => {
        return snackbar
          .open(message, '', {
            duration: 3500,
            panelClass: 'styled-snackbar',
          });
      })
    )

  }

  public showError(message: string) {
    return from(this.getSnackbar()).pipe(
      switchMap((snackbar) => {
        return snackbar
          .open(message, '', {
            duration: 3500,
            panelClass: 'styled-snackbar',
          }).onAction().subscribe(() => {
            window.location.reload();
          });
      })
    )

  }

  public showSucces(params: {message: string, actionText: string, duration: number}) {
    return from(this.getSnackbar()).pipe(
      switchMap((snackbar) => {
        return snackbar
          .open(params.message,params.actionText, {
            duration: params.duration,
            panelClass: 'styled-snackbar',
          })
      })
    )
  }

}
