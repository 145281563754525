import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SetOrganisation, UpdateOrganisation, ClearOrganisation, OrganisationStateModel, UpdateBillingDetails, CancelSubscription, ChangePaymentTerm, UpgradeSubscription, SetSubscriptionCancellationReason } from './models/organisation.state.model';
import { OrganisationService } from '../services/organisation.service';
import { firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { StateNames } from '../common/state-names.enum';
import { ApplicationSubscription } from '../interfaces/subscription.model';
import { SubscriptionService } from '../services/subscription.service';
import { USER_PROPERTIES } from '../config/user-properties';
import { Organisation } from '../interfaces/organisation.model';

const defaults = {
    id: null,
    name: null,
    is_private: false,
    mollie_id: null,
    allowed_user_properties: [],
    createdAt: new Date(),
    updatedAt: new Date(),
    billing_details: null,
    active_subscription: null
};

@State<OrganisationStateModel>({
    name: StateNames.organisationv1,
    defaults: defaults
})
@Injectable()
export class OrganisationState {
    @Selector([OrganisationState])
    static organisation(state: OrganisationStateModel) {
        return state;
    }

    @Selector([OrganisationState])
    static billingDetails(state: OrganisationStateModel) {
        return state.billing_details;
    }

    @Selector([OrganisationState])
    static active_subscription(state: OrganisationStateModel) {
        return state.active_subscription;
    }

    // Return the subscription that is currently active.
    @Selector([OrganisationState.active_subscription])
    static activeSubscriptionIsCancelled(subscription: ApplicationSubscription) {
        return subscription.end !== null;
    }

    @Selector([OrganisationState.organisation])
    static allowedUserProperties(organisation: Organisation) {
        console.log(organisation.allowed_user_properties)
        return USER_PROPERTIES.filter(userProperty => organisation.allowed_user_properties.includes(userProperty.name));
    }

    constructor(private organisationService: OrganisationService, private subscriptionService: SubscriptionService) {
    }

    @Action(SetOrganisation)
    setOrganisation(ctx: StateContext<OrganisationStateModel>, action: SetOrganisation) {
        ctx.setState(action.payload);
    }

    @Action(UpdateBillingDetails)
    async updateBillingDetails(ctx: StateContext<OrganisationStateModel>, action: UpdateBillingDetails) {
        const organisationBillingDetails = await firstValueFrom(this.organisationService.updateBillingDetails(action.payload.organisation_id, action.payload.updated_billing_details));
        ctx.patchState(organisationBillingDetails);
    }

    @Action(UpdateOrganisation)
    async updateOrganisation(ctx: StateContext<OrganisationStateModel>, action: UpdateOrganisation) {
        const organisation = await firstValueFrom(this.organisationService.update(action.payload.id, action.payload));
        ctx.patchState(organisation);
    }

    @Action(ClearOrganisation)
    clearOrganisation(ctx: StateContext<OrganisationStateModel>) {
        ctx.setState(defaults);
    }


    @Action(UpgradeSubscription)
    async upgradeSubscription(ctx: StateContext<OrganisationStateModel>, action: UpgradeSubscription) {
        await firstValueFrom(this.subscriptionService.upgradeSubscription(
            action.payload.currentSubscription,
            action.payload.futureSubscriptionType,
            action.payload.futurePaymentTerm));

        const organisationId = ctx.getState().id;
        const organisation = await firstValueFrom(this.organisationService.get(organisationId, { cacheBust: true }));
        ctx.patchState(organisation);
    }


    @Action(CancelSubscription)
    async cancelSubscription(ctx: StateContext<OrganisationStateModel>, action: CancelSubscription) {
        await firstValueFrom(this.subscriptionService.cancelSubscription(action.payload.subscriptionId));
        const organisation = await firstValueFrom(this.organisationService.get(ctx.getState().id, { cacheBust: true }));
        ctx.patchState(organisation);
    }

    @Action(ChangePaymentTerm)
    async changePaymentTerm(ctx: StateContext<OrganisationStateModel>, action: ChangePaymentTerm) {
        await firstValueFrom(this.subscriptionService.changePaymentTerm(action.payload.currentSubscription, action.payload.futurePaymentTermName));
        const organisationId = ctx.getState().id;
        const organisation = await firstValueFrom(this.organisationService.get(organisationId, { cacheBust: true }));
        ctx.patchState(organisation);
    }

    
    @Action(SetSubscriptionCancellationReason)
    async setSubscriptionCancellationReason(ctx: StateContext<OrganisationStateModel>, action: SetSubscriptionCancellationReason) {
        const subscription = await firstValueFrom(this.subscriptionService.setCancellationReason(action.payload.subscriptionId, {
            cancellationReason: action.payload.cancellationReason,
            satisfied: action.payload.satisfied
        }));
        const organisationId = ctx.getState().id;
        const organisation = await firstValueFrom(this.organisationService.get(organisationId, { cacheBust: true }));
        ctx.patchState(organisation);
    }

}