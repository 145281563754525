import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { CoreModule } from '../core.module';
import { OrganisationState } from '../states/organisation.state';
import { AuthState } from '../states/auth.state';

@Injectable({
  providedIn: CoreModule
})
export class BillingDetailsGuard {
  constructor(
    private store: Store,
    private router: Router) { }

  async canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    // Check if authenticated.
  
    const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);

    // If not, return true
    if (!isAuthenticated) { 
      return true;
    }

    // If no billing details, redirect to billing details page
    const billingDetails = this.store.selectSnapshot(OrganisationState.billingDetails);
    if (billingDetails?.city === undefined || billingDetails?.city === null) {
      this.router.navigate(['/', 'profiel', 'gegevens'], {
        queryParams: next.queryParams,
      });
      return false;
    }

    return true;
  }

}
