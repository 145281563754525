import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { CoreModule } from '@app/core/core.module';
import { HttpClient } from '@angular/common/http';
import { Organisation, OrganisationBillingDetails } from '../interfaces/organisation.model';
import { ApplicationSubscription } from '../interfaces/subscription.model';
import { Order } from '../interfaces/order.model';
import { Mandate } from '@app/core/interfaces/mandate.interface';
@Injectable({
    providedIn: CoreModule
})
export class OrganisationService {
    private apiLocation = environment.apiDomain + environment.apiUrl;

    constructor(private http: HttpClient) { }

    public get(organisationId: string, opts: { cacheBust: boolean } = { cacheBust: false }) {

        const params = {};

        if (opts.cacheBust === true) {
            params['cacheBust'] = new Date().getTime();
        }

        return this.http.get<Organisation>(`${this.apiLocation}/organisation/${organisationId}`, { params });
    }

    public updateBillingDetails(organisationId: string, updatedBillingDetails: Partial<OrganisationBillingDetails>) {
        return this.http.patch<Organisation>(`${this.apiLocation}/organisation/${organisationId}/billing-details`, updatedBillingDetails);
    }

    public update(organisationId: string, updatedOrganisation: Partial<Organisation>) {
        return this.http.patch<Organisation>(`${this.apiLocation}/organisation/${organisationId}`, updatedOrganisation);
    }

    public listUserApiKeys(organisationId: string) {
        return this.http.get<{ id: string; createdAt: string }[]>(this.apiLocation + `/organisation/${organisationId}/api-key`);
    }

    public disableApiKey(organisationId: string, apiKeyId: string) {
        return this.http.delete(this.apiLocation + `/organisation/${organisationId}/api-key/${apiKeyId}`);
    }

    public createApiKey(organisationId: string) {
        return this.http.post(this.apiLocation + `/organisation/${organisationId}/api-key`, null);
    }

    public listOrganisationSubscriptions(organisationId: string) {
        return this.http.get<ApplicationSubscription[]>(this.apiLocation + `/organisation/${organisationId}/subscription`);
    }

    public getMandate(organisationId: string) {
        return this.http.get<Mandate>(this.apiLocation + `/organisation/${organisationId}/mandate`);
    }

    public updateMandate(organisationId: string) {
        return this.http.patch<any>(this.apiLocation + `/organisation/${organisationId}/mandate`, {});
    }

    public getMyOrders(organisationId: string) {
        return this.http.get<Order[]>(this.apiLocation + `/organisation/${organisationId}/order`);
    }


    public listInvoices(organisationId: string) {
        return this.http.get<{ id: string, invoice_id: string, invoice_date: string }[]>(`${this.apiLocation}/organisation/${organisationId}/invoice`);
    }

    public downloadInvoice(organisationId: string, invoiceId: string) {
        return this.http.get<Blob>(`${this.apiLocation}/organisation/${organisationId}/invoice/${invoiceId}/pdf`, {
            responseType: 'blob' as any
        });
    }

    public getStatistics(organisationId: string) {
        return this.http.get<any>(this.apiLocation + `/organisation/${organisationId}/statistics`);
    }

}
