import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  imports: [],
  templateUrl: './skeleton-loader.component.html',
  styleUrl: './skeleton-loader.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonLoaderComponent {
  readonly type = input<'text' | 'image' | 'menu-item' | 'paragraph'>(undefined);
  readonly lines = input<number>(1);
  readonly height = input<string>('20px');
  readonly width = input<string>('100%');
  readonly animate = input<boolean>(true);

  readonly linesArray = computed(() => this.getArray(this.lines()));

  public getArray(length: number): number[] {
    return new Array(length).fill(0);
  }

}

