import { Action, Selector, State, StateContext } from "@ngxs/store";
import { ChangeStateModel, ListChanges, SetChangeHighlight } from "./models/change.state.model";
import { Injectable } from "@angular/core";
import { AlertService } from "../services/alert.service";
import { firstValueFrom } from "rxjs";



@State<ChangeStateModel>({
    name: 'changes',
    defaults: {
        changes: [],
        highlighted: []
    }
})
@Injectable()
export class ChangeState {

    @Selector([ChangeState])
    static changes(state: ChangeStateModel) {
        return state.changes;
    }

    @Selector([ChangeState])
    static highlightedFeatures(state: ChangeStateModel) {
        return state.changes.filter(change => state.highlighted.includes(change.id));
    }

    constructor(
        private changeNotificationService: AlertService,
    ) { }

    @Action(ListChanges)
    async listChanges(ctx: StateContext<ChangeStateModel>, action: ListChanges) {
        const changes = await firstValueFrom(this.changeNotificationService.findAllChanges(action.payload.bounds, {
            fromDate: action.payload.dateRange.fromDate,
            toDate: action.payload.dateRange.toDate
        }, action.payload.types));
        ctx.setState({
            changes,
            highlighted: []
        });
    }

    @Action(SetChangeHighlight)
    setHighlight(ctx: StateContext<ChangeStateModel>, action: SetChangeHighlight) {
        ctx.patchState({
            highlighted: action.payload.ids
        });
    }

}

