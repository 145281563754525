import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CoreModule } from '@app/core/core.module';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: CoreModule
})
export class SpinService {
  private pendingRequestCount$ = new BehaviorSubject<number>(0);
  public showSpinner$ = this.pendingRequestCount$.pipe(
    map(count => count > 0 ? true : false),
    debounceTime(30),
    distinctUntilChanged()
  );

  constructor() {
  }

  public addRequestCount() {
    const pendingRequestCount = this.pendingRequestCount$.getValue();
    const newRequestCount = pendingRequestCount + 1;
    this.pendingRequestCount$.next(newRequestCount);
  }

  public subtractRequestCount() {
    const pendingRequestCount = this.pendingRequestCount$.getValue();
    const newRequestCount = pendingRequestCount - 1;
    this.pendingRequestCount$.next(newRequestCount);
  }
}
