import { User } from "@app/core/interfaces/user.model";

export interface UserStateModel extends User {
    mandate: any;
}

export class GetUser {
    static readonly type = '[User] Get';
    constructor(public payload: {userAuth0Id: string}) {
    }
}

export class UpdateUser {
    static readonly type = '[User] Update';
    constructor(public payload: {userAuth0Id: string, updatedUser: Partial<User>}) {
    }
}

export class GetUserInvoiceAddress {
    static readonly type = '[User] GetInvoiceAddress';
    constructor(public payload: {userAuth0Id: string}) { }
}

export class ClearUser {
    static readonly type = '[User] Clear';
    constructor() {
    }
}
