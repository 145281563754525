import { Injectable } from "@angular/core";
import { environment } from 'environments/environment';
import Rollbar from "rollbar";
import { map, from, firstValueFrom } from "rxjs";
import { APP_VERSION } from "@app/core/config/config";
import { CoreModule } from "@app/core/core.module";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable({
  providedIn: CoreModule
})
export class RollbarService {
  public settings = {
    accessToken: environment.rollbar.apiKey,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: environment.environmentName,
    enabled: environment.rollbar.enableRollbar,
    itemsPerMinute: 5,
    captureIp: "anonymize" as any,
    payload: {
      code_version: APP_VERSION,
    }
  };

  public rollbar: Rollbar | undefined;
  public finalUrls: string[] = [];
  constructor() {

  }

  public async getRollbar() {
    if (this.rollbar !== undefined) {
      return this.rollbar;
    }

    const Rollbar = await firstValueFrom(from(import('rollbar')).pipe(
      map(module => module.default)
    ));

    this.rollbar = new Rollbar(this.settings);

    return this.rollbar;
  }


  public sanitizeError(error: any) {
    if (error instanceof Error || error instanceof String) {
      return [error];
    } else if (error instanceof HttpErrorResponse) {
      return [error.message, error];
    } else {
      return ['Error', error];
    }
  }


}