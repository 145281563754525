import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { CoreModule } from "../core.module";
import { OrganisationService } from "../services/organisation.service";
import { Store } from "@ngxs/store";
import { AuthState } from "../states/auth.state";
import { SetOrganisation } from "../states/models/organisation.state.model";
import { firstValueFrom } from "rxjs";

@Injectable({ providedIn: CoreModule })
export class OrganisationResolver {
    constructor(private organisationService: OrganisationService, private store: Store) { }
    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {


        const organisationId = this.store.selectSnapshot(AuthState.organisation_id);
        const organisation = await firstValueFrom(this.organisationService.get(organisationId));

        await this.store.dispatch(new SetOrganisation(organisation)).toPromise();

        // const billingDetails = await firstValueFrom(this.organisationService.getBillingDetails(organisationId));

        return organisation;

    }
}