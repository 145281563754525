import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@app/core/services/app.service';
import { UserState } from '@app/core/states/user.state';
import { Store } from '@ngxs/store';
import { combineLatest, filter, firstValueFrom, map, shareReplay, startWith } from 'rxjs';
import { MenuComponent } from '../menu/menu.component';
import { GroupNames } from '@app/core/interfaces/group-names.enum';
import { ShoppingCartState } from '@app/core/states/shopping-cart.state';
import { StoryBlokService } from '@app/core/services/blog-data.service';
import { AppState } from '@app/core/states/app.state';
import dayjs from 'dayjs';
import { SubscriptionIndicatorChipComponent } from '@app/shared/subscription-indicator-chip/subscription-indicator-chip.component';
import { SetLastRead } from '@app/core/states/models/app.state.model';
import { AuthService } from '@app/core/services/auth.service';
import { AuthState } from '@app/core/states/auth.state';
import { AccountMenuService } from '@app/core/services/account-menu.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { OrganisationState } from '@app/core/states/organisation.state';

@Component({
  selector: 'app-account-button',
  imports: [MenuComponent, SubscriptionIndicatorChipComponent],
  templateUrl: './account-button.component.html',
  styleUrl: './account-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountButtonComponent {
  public readonly user = toSignal(this.store.select(UserState.user).pipe(
    map(user => {
      if (user === null || user === undefined) {
        return null;
      };

      // TODO Workaround voor foto
      return user;
    })
  ));
  public readonly currentSubscription = toSignal(this.store.select(OrganisationState.active_subscription).pipe(
    filter(subscription => subscription !== null && subscription !== undefined),
  ));
  public readonly isMobile = toSignal(this.appService.tabletView$);
  private isAdmin$ = this.store.select(AuthState.hasRole(GroupNames.admin));
  private itemsInCart$ = this.store.select(ShoppingCartState.cartItems).pipe(
    map((items) => items.length)
  );
  public showAccountMenu = signal<boolean>(false);
  public notifications$ = this.storyBlokService.listPosts('nieuws/', { perPage: 3, withTag: ['Notification'] }).pipe(
    map(response => response.stories),
    startWith([]),
    shareReplay()
  );

  public lastRead$ = this.store.select(AppState.lastRead).pipe(
    map(lastRead => {
      return lastRead === undefined || lastRead === null ? dayjs().subtract(5, 'year').toDate() : lastRead
    })
  );

  public notificationsWithReadStatus$ = combineLatest([this.lastRead$, this.notifications$]).pipe(
    map(([lastRead, notifications]) => {
      return notifications.map((notification) => {
        return {
          ...notification,
          unread: new Date(lastRead).getTime() < new Date(notification.created_at).getTime()
        }
      })
    }),
  );

  public loggedInMenuItems = toSignal(combineLatest([this.isAdmin$, this.itemsInCart$]).pipe(
    startWith([false, 0, [] as any]),
    map(([isAdmin, itemsInCart, notifications]) => {
      return this.accountMenuService.getMenuItems({ isAdmin, withBorder: true });
    })
  ));

  public loggedOutMenuItems = toSignal(combineLatest([this.itemsInCart$]).pipe(
    startWith([0, [] as any]),
    map(([itemsInCart]) => {
      return [
        {
          label: 'Upgrade uw account',
          items: [],
          routerLink: '/upgrade',
          icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-star',
          emphasize: true
        },
        {
          label: 'Inloggen',
          items: [],
          routerLink: 'account/inloggen',
          icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-user',
        },
        {
          label: 'Registeren',
          items: [],
          routerLink: 'account/aanmaken',
          icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-plus-circle',
        },
        {
          label: 'Mijn winkelwagen',
          subLabel: itemsInCart === 1 ? `${itemsInCart} product` : `${itemsInCart} producten`,
          items: [],
          routerLink: '/afrekenen/besteloverzicht',
          icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-shopping-cart'
        },
      ];
    })
  ));

  constructor(
    private store: Store,
    private auth: AuthService,
    private router: Router,
    private storyBlokService: StoryBlokService,
    private accountMenuService: AccountMenuService,
    private appService: AppService) {
  }

  public login() {
    this.auth.goToLogin(this.router.url.split('(')[0]);
  }

  public signup() {
    this.auth.goToRegister(this.router.url.split('(')[0]);
  }

  public async openMenu() {
    const isDesktop = await firstValueFrom(this.appService.desktopXLView$);
    if (isDesktop) {
      return this.router.navigate(['/profiel/dashboard']);
    }

    return this.showAccountMenu.set(true);
  }
  public closeMenu() {
    this.showAccountMenu.set(false);
  }

  public hasUnreadMessages$ = this.notificationsWithReadStatus$.pipe(
    map(messages => {
      return messages.filter(message => message.unread === true)
    }),
    map(unreadMessages => {
      return unreadMessages.length > 0;
    })
  );
  public markAsRead() {
    this.store.dispatch(new SetLastRead());
  }






}


