import { Injectable } from '@angular/core';
import { GrowthBook, InitOptions } from '@growthbook/growthbook';
import { CoreModule } from '../core.module';
import { environment } from 'environments/environment';
import { GoogleAnalyticsService } from '../plugins/google-analytics/google-analytics.service';

@Injectable({
    providedIn: CoreModule
})
export class GrowthBookService {
    private growthbook: GrowthBook;

    constructor(private ga: GoogleAnalyticsService) {
        // Initialize GrowthBook instance with default attributes.
        // These attributes can be updated later based on the logged-in user.
        this.growthbook = new GrowthBook({
            apiHost: environment.growthbook.apiHost,
            clientKey: environment.growthbook.clientKey,
            enableDevMode: environment.growthbook.enableDevMode,
            attributes: {
                // Set initial attributes
                id: this.getId(),
            },
            // Optional: If you want to log events from GrowthBook
            trackingCallback: (experiment, result) => {
                this.ga.event('viewed_experiment', {
                    experiment_id: experiment.key,
                    variation_id: result.variationId,
                    id: this.getId()
                });

                if (environment.growthbook.enableDevMode === true) {
                    console.log(`Viewed experiment: ${experiment.key} - Variation: ${result.variationId}`);
                }
            }
        });

    }

    async init() {
        const initOptions: InitOptions = {
            timeout: 500,
            streaming: false,
        };
        await this.growthbook.init(initOptions);

    }


    // Update user attributes (e.g., after authentication)
    setUserAttributes(attributes: Record<string, any>): void {
        this.growthbook.setAttributes(attributes);
    }

    // Check if a feature is enabled
    isFeatureEnabled(featureKey: string): boolean {
        return this.growthbook.isOn(featureKey);
    }

    private getId() {
        const id = localStorage.getItem('growthbook');
        if (id !== null && id !== undefined) {
            return +id;
        }

        const newId = `${parseInt(Math.random() * 10000000 + '')}`;
        localStorage.setItem('growthbook', newId);
        return newId;

    }
}