import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-subscription-indicator-chip',
  imports: [NgClass],
  template: `
    @if (type === 'plus') {
      <span class="chip chip--plus" [ngClass]="{'chip--tiny': size === 'tiny', 'chip--medium': size === 'medium'}"> Plus </span>
    } @else if (type === 'pro') {
      <span class="chip chip--pro" [ngClass]="{'chip--tiny': size === 'tiny', 'chip--medium': size === 'medium'}"> Pro </span>
    } @else if (type === 'enterprise') {
      <span class="chip chip--enterprise" [ngClass]="{'chip--tiny': size === 'tiny', 'chip--medium': size === 'medium'}"> Ent </span>
    }
  `,
  styleUrl: './subscription-indicator-chip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class SubscriptionIndicatorChipComponent {
  @Input() type: 'plus' | 'pro' | 'enterprise' = 'plus';
  @Input() size: 'tiny' | 'medium' | 'standard' = 'standard';
}
