import { Injectable } from "@angular/core";
import { State, Selector, Action, StateContext, createSelector } from "@ngxs/store";
import { produce } from "immer";
import { firstValueFrom } from "rxjs";
import { tap } from "rxjs/operators";
import { FileRef } from "../interfaces/file-ref";
import { FileService } from "../services/file.service";
import { CreateFile, FilesStateModel, ListFiles, RemoveFile } from "./models/file.state.model";

@State<FilesStateModel>({
  name: 'files',
  defaults: {
    files: {}
  }
})
@Injectable()
export class FilesState {
  @Selector([FilesState])
  static files(state: FilesStateModel) {
    return Object.values(state.files);
  }

  static userParcelFiles(userParcelId: string) {
    return createSelector([FilesState.files], (state: FileRef[]) => {
      return state.filter(fileRef => fileRef.userParcelId === userParcelId);
    });
  }

  constructor(
    private fileService: FileService
  ) { }


  @Action(ListFiles)
  listFiles(ctx: StateContext<FilesStateModel>, action: ListFiles) {
    return this.fileService.listOrganisationFiles(action.payload.organisation_id).pipe(
      tap(files => {
        const filesMap = files.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.id]: curr
          }
        }, {});
        ctx.setState({ files: filesMap })
      })
    );
  }


  @Action(CreateFile)
  async createFile(ctx: StateContext<FilesStateModel>, action: CreateFile) {
    let updatedFileRef = action.payload.file;
    if (Object.values(action.payload.linkOpts).length !== 0) {
      updatedFileRef = await firstValueFrom(this.fileService.linkFile(action.payload.file.id, action.payload.linkOpts));
    }

    const nextState = produce(ctx.getState(), draft => {
      draft.files[updatedFileRef.id] = updatedFileRef;
    });

    ctx.setState(nextState);
  }

  @Action(RemoveFile)
  async deleteFile(ctx: StateContext<FilesStateModel>, action: RemoveFile) {

    await firstValueFrom(this.fileService.deleteFileRef(action.payload.fileId));

    const nextState = produce(ctx.getState(), draft => {
      delete draft.files[action.payload.fileId];
    });
    ctx.setState(nextState);
  }
}

