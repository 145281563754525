import { Injectable } from '@angular/core';

import { CoreModule } from '../core.module';
import { Store } from '@ngxs/store';
import { environment } from 'environments/environment';
import { UserState } from '../states/user.state';
import { AppService } from './app.service';
import { OrganisationState } from '../states/organisation.state';
@Injectable({
    providedIn: CoreModule
})
export class SurveyService {

    public availableSurveys = {
        user_satisfaction: 'kkJ23BCm',
        notification_interest: 'phHZG1dA',
        search_interest: 'cq4TKYqP',
        search_review: 'f7gZbxu9',
        notifciation_review: 'u4eKulY6',
        unregistered_users_review: 'kUpWbl6i',
        projectontwikkelaars_survey: 'DqARiROI'
    };

    constructor(
        private store: Store,
        private appService: AppService
    ) { }

    public async showSurvey(id: string, params?: { user_connection_id: string, user_id: string }) {

        this.appService.lazyLoadStyle('typeform--slider--1');
        const { createSlider } = await import('@typeform/embed');

        const subscriptionTypeName = this.store.selectSnapshot(OrganisationState.active_subscription)?.subscriptionType?.name || 'none';
        const email = this.store.selectSnapshot(UserState.user)?.email;

        createSlider(id, {
            enableSandbox: environment.production === false,
            displayAsFullScreenModal: true,
            hideHeaders: true,
            hideFooter: true,
            hidden: {
                subscription_type: subscriptionTypeName,
                email: email,
                user_connection_id: params?.user_connection_id
            }
        }).toggle();
    }

}
