import { Injectable } from '@angular/core';
import { UserParcel } from '../interfaces/user-parcel.model';
import { environment } from 'environments/environment';
import { CoreModule } from '@app/core/core.module';

import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: CoreModule
})
export class UserParcelService {
    private apiLocation = environment.apiDomain + environment.apiUrl;

    constructor(private http: HttpClient) { }

    public listTags(organisationId: string) {
        return this.http.get<string[]>(this.apiLocation + `/organisation/${organisationId}/tag`);
    }

    public addParcels(organisationId: string, userParcels: Partial<UserParcel>[]) {
        return this.http.post<UserParcel[]>(this.apiLocation + `/organisation/${organisationId}/parcel`, userParcels);
    }

    public listParcels(params: {
        organisation_id: string;
        limit: string;
        offset: string;
        sortBy?: string;
        tags?: string[];
        searchTerm?: string;
        minSize?: string;
        maxSize?: string;
        bbox?: string;
    }) {
        return this.http.get<{
            count: number;
            limit: string;
            offset: string;
            rows: UserParcel[];
        }>(this.apiLocation + `/organisation/${params.organisation_id}/parcel`, {
            params: params
        });
    }


    public getParcel(id: string) {
        return this.http.get<UserParcel>(this.apiLocation + `/user-parcel/${id}`);
    }

    public removeParcel(id: string) {
        return this.http.delete<void>(this.apiLocation + `/user-parcel/${id}`);
    }

    public updateParcel(id: string, userParcel: UserParcel) {
        return this.http.put<UserParcel>(
            this.apiLocation + `/user-parcel/${id}`,
            userParcel
        );
    }



}