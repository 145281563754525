import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-header-menutray',
    templateUrl: './header-menutray.component.html',
    styleUrl: './header-menutray.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderMenutrayComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter<void>();
  @Input() contentPosition: 'left' | 'center' = 'left';

  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) { }


  ngOnInit(): void {
    this.renderer2.addClass(this.document.body, 'header__menutray--' + this.contentPosition);
  }

  ngOnDestroy(): void {
    this.renderer2.removeClass(this.document.body, 'header__menutray--' + this.contentPosition);
  }
}
