import { AfterViewInit, ChangeDetectionStrategy, Component, createNgModuleRef, Inject, Injector, OnInit, Renderer2, signal, WritableSignal } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { GroupNames } from '@app/core/interfaces/group-names.enum';
import { AppService } from '@app/core/services/app.service';
import { ShoppingCartState } from '@app/core/states/shopping-cart.state';
import { Store } from '@ngxs/store';
import { Subscription, combineLatest } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { AppState } from '@app/core/states/app.state';
import { SetBannerClose } from '@app/core/states/models/app.state.model';
import { MenuComponent } from '../menu/menu.component';
import { SpinComponent } from '@app/shared/spin/spin.component';
import { DatHuisService } from '@app/core/services/dathuis.service';
import { AccountButtonComponent } from '../account-button/account-button.component';
import { MenuItem } from '../menu/menu.interface';
import { HeaderMenutrayComponent } from '../header-menutray/header-menutray.component';
import { AuthState } from '@app/core/states/auth.state';
import { AuthService } from '@app/core/services/auth.service';
import { HeaderSearchComponent } from '../header-search/header-search.component';
import { StoryBlokService } from '@app/core/services/blog-data.service';
import { environment } from 'environments/environment';
import { SpinService } from '@app/core/services/spin.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { SkeletonLoaderComponent } from '@app/shared/skeleton-loader/skeleton-loader.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    MenuComponent,
    SpinComponent,
    AccountButtonComponent,
    HeaderMenutrayComponent,
    HeaderSearchComponent,
    SkeletonLoaderComponent
  ]
})
export class HeaderComponent implements OnInit, AfterViewInit {
  private subscriptions: Subscription[] = [];
  public readonly showItem: WritableSignal<null | 'notifications' | 'menu' | 'mainDesktopNavMenu' | 'moreDesktopNavMenu' | 'search'> = signal(null);
  public GroupNames = GroupNames;
  private previousScrollTop = 0;
  public initialHeaderHeight = 58;
  public readonly headerDisplacement = signal(this.initialHeaderHeight);

  public readonly itemsInCart = toSignal(this.store.select(ShoppingCartState.cartItems).pipe(map(cart => cart.length)));
  public readonly isMobile = toSignal(this.appService.tabletView$);
  public readonly isPro = toSignal(this.store.select(AuthState.hasRole(GroupNames.pro)));
  private hasAdvancedSearchPermission$ = this.store.select(AuthState.hasPermissions(['advanced_search:map:use']));

  public mainDesktopNavMenu = toSignal(this.hasAdvancedSearchPermission$.pipe(
    map(hasAdvancedSearchPermission => {
      const mainDesktopNavMenu: MenuItem[] = [
        {
          label: 'Percelen',
          subLabel: 'Bekijk percelen op de kaart',
          items: [],
          routerLink: '/',
          icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-layout',
        },
        {
          label: 'Adressen',
          subLabel: 'Informatie over elk adres in Nederland',
          items: [],
          routerLink: '/adres',
          icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-home',
        },
        {
          label: 'Bestemmingen',
          subLabel: 'Wat er wél en niet mag op een perceel',
          items: [],
          routerLink: '/omgevingswet',
          subscriptionIndicator: 'plus',
          icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-file-text',
        },
        {
          label: 'Gebeurtenissen',
          subLabel: 'Blijf op de hoogte van lokale ontwikkelingen',
          items: [],
          routerLink: '/gebeurtenissen',
          subscriptionIndicator: 'plus',
          icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-calendar'
        },
        {
          label: 'Geavanceerd zoeken',
          subLabel: 'Vind en exporteer percelen o.b.v. uw criteria',
          items: [],
          routerLink: this.getExportUrl(hasAdvancedSearchPermission),
          subscriptionIndicator: 'pro',
          icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-filter',
          noBorder: true
        },
      ];
      return mainDesktopNavMenu
    })
  ));

  public readonly mobileMenuItems = toSignal(this.hasAdvancedSearchPermission$.pipe(
    map((hasAdvancedSearchPermission) => {
      return this.formatMobileMenu(hasAdvancedSearchPermission);
    })
  ));

  public moreDesktopNavMenu: MenuItem[] = [
    {
      header: 'Support',
      label: 'Handleiding',
      items: [],
      routerLink: '/handleiding',
      icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-book'
    },
    {
      label: 'Veelgestelde vragen',
      items: [],
      routerLink: '/veelgestelde-vragen',
      icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-help-circle'
    },
    {
      label: 'Contact',
      items: [],
      routerLink: '/support',
      icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-message-square',
      noBorder: true
    },
    {
      header: 'Kennisbank',
      label: 'Begrippen',
      icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-book-open',
      routerLink: '/begrippen',
      items: []
    },
    {
      label: 'Woonplaatsen',
      icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-book-open',
      routerLink: '/woonplaatsen',
      items: []
    },
    {
      label: 'Kadastrale gemeenten',
      icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-book-open',
      routerLink: '/kadastrale-gemeenten',
      items: []
    },
    {
      label: 'Gemeenten',
      icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-book-open',
      routerLink: '/gemeenten',
      items: []
    },
    {
      label: 'Wijken',
      icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-book-open',
      routerLink: '/wijken',
      items: []
    },
    {
      label: 'Buurten',
      icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-book-open',
      routerLink: '/buurten',
      items: [],
      noBorder: true
    },
    {
      header: 'Overig',
      label: 'Nieuws',
      items: [],
      routerLink: '/nieuws',
      icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-mail'
    },
    {
      label: 'API',
      items: [],
      routerLink: '/upgrade/api',
      icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-repeat'
    },
    {
      label: 'Maatwerk',
      items: [],
      routerLink: '/maatwerk',
      icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-pen-tool'
    },
    {
      label: 'Over ons',
      items: [],
      routerLink: '/over-ons',
      icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-info',
      noBorder: true
    },
  ];

  public showUpdateBanner = toSignal(this.store.select(AppState.showUpdateBanner).pipe(
    map(showUpdateBanner => {
      if (environment.keepShowingBanner === true) {
        return true;
      }
      return showUpdateBanner;
    }),
    tap(showBanner => {
      if (environment.keepShowingBanner === true) {
        this.renderer2.setProperty(this.document.querySelector(':root'), 'style', '--header-height:98px');
      } else {
        if (showBanner === true) {
          this.renderer2.setProperty(this.document.querySelector(':root'), 'style', '--header-height:98px');
        }
        if (showBanner === false) {
          this.renderer2.setProperty(this.document.querySelector(':root'), 'style', '--header-height:58px');
        }
      }
    },
    )));

  public story = toSignal(this.store.select(AppState.showUpdateBanner).pipe(
    map(showUpdateBanner => {
      if (environment.keepShowingBanner === true) {
        return true;
      }
      return showUpdateBanner;
    }),
    filter(showUpdateBanner => showUpdateBanner === true),
    switchMap(() => {
      return this.storyBlokService.listPosts('nieuws/', { perPage: 1, withTag: ['Update'] });
    }),
    map((response) => {
      return response.stories[0];
    }),
    shareReplay(),
  ));

  constructor(
    private router: Router,
    private store: Store,
    private appService: AppService,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private storyBlokService: StoryBlokService,
    private spinService: SpinService,
    // private dialog: MatDialog
    private injector: Injector,
  ) {


  }
  // private latestUpdate$ = this.storyBlokService.listPosts('nieuws/', { perPage: 1, withTag: ['Update'] }).subscribe(console.log)
  public isAuthenticated$ = this.store.select(AuthState.isAuthenticated);

  // private lastRead$ = this.store.select(AppState.lastRead).pipe(
  //   map(lastRead => {
  //     return lastRead === undefined || lastRead === null ? dayjs().subtract(5, 'year').toDate() : lastRead
  //   })
  // );

  public readonly showSpinner = toSignal(this.spinService.showSpinner$);

  private outsideCheckout$ = this.router.events.pipe(
    map(e => {
      const url = this.router.url;
      if (url.includes('afrekenen') || url.includes('profiel/abonnement-afsluiten')) {
        return false;
      } else {
        return true;
      }
    }),
    startWith(true),
  );

  public readonly hideItems = toSignal(this.outsideCheckout$.pipe(
    map(outsideCheckout => !outsideCheckout),
  ));

  ngOnInit() {
    // Run this scroll event listener outside angular to prevent running change detection for the whole application.
    this.document.defaultView.addEventListener('scroll', (event) => {
      this.hideHeaderOnMobile(event);
    });

    this.subscriptions.push(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.toggleShowItem(null);
      })
    );
  }

  ngAfterViewInit(): void { }

  private async lazyLoadMatDialog() {
    const { MatDialogModule: MatDialogModule } = await import("@angular/material/dialog");
    const moduleRef = createNgModuleRef(MatDialogModule, this.injector);

    const { MatDialog: MatDialog } = await import("@angular/material/dialog");
    const dialog = moduleRef.injector.get(MatDialog);
    return dialog;
  }

  public async toggleShowItem(item: null | 'notifications' | 'menu' | 'mainDesktopNavMenu' | 'moreDesktopNavMenu' | 'search') {
    const dialog = await this.lazyLoadMatDialog();
    dialog.closeAll();

    if (this.showItem() === item) {
      this.showItem.set(null);
    } else {
      this.showItem.set(item);
    }
  }

  public login() {
    this.authService.goToLogin(this.router.url.split('(')[0]);
  }

  private clamp(val: number, min: number, max: number) {
    return val > max ? max : val < min ? min : val;
  }

  private hideHeaderOnMobile(event: any) {
    let currentScrollTop = Math.max(0, event.target.scrollingElement.scrollTop);

    if (this.appService.hasMinLaptopWidth()) {

      this.headerDisplacement.set(58);
      this.previousScrollTop = 0;
      return;
    }

    // Prevents overscroll funniness when pulling down to refresh
    currentScrollTop = Math.max(0, event.target.scrollingElement.scrollTop);
    this.headerDisplacement.set(this.headerDisplacement() - (currentScrollTop - this.previousScrollTop));
    const clampedDisplacement = this.clamp(this.headerDisplacement(), 0, 58);

    this.headerDisplacement.set(clampedDisplacement);
    this.previousScrollTop = currentScrollTop;

  }

  public close() {
    this.store.dispatch(new SetBannerClose());
  }

  private formatMobileMenu(hasAdvancedSearchPermission: boolean) {
    const menu: MenuItem[] = [
      {
        label: 'Percelen',
        subLabel: 'Bekijk percelen op de kaart',
        items: [],
        routerLink: '/',
        icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-layout',
      },
      {
        label: 'Adressen',
        subLabel: 'Informatie over elk adres in Nederland',
        items: [],
        routerLink: '/adres',
        icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-home',
      },
      {
        label: 'Bestemmingen',
        subLabel: 'Wat er wel en niet is toegestaan op een perceel',
        items: [],
        routerLink: '/omgevingswet',
        subscriptionIndicator: 'plus',
        icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-file-text',
      },
      {
        label: 'Gebeurtenissen',
        subLabel: 'Blijf op de hoogte van lokale ontwikkelingen',
        items: [],
        routerLink: '/gebeurtenissen',
        subscriptionIndicator: 'plus',
        icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-calendar'
      },
      {
        label: 'Geavanceerd zoeken',
        subLabel: 'Vind en exporteer percelen o.b.v. uw criteria',
        items: [],
        routerLink: this.getExportUrl(hasAdvancedSearchPermission),
        subscriptionIndicator: 'pro',
        icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-filter'
      },
      {
        label: 'Webwinkel',
        items: [],
        routerLink: '/producten',
        icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-shopping-cart'
      },
      {
        label: 'Upgrade',
        items: [],
        routerLink: '/upgrade',
        icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-star',
        emphasize: true
      },
      {
        label: 'Meer',
        icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-book-open',
        items: [
          {
            header: 'Support',
            label: 'Handleiding',
            items: [],
            routerLink: '/handleiding',
            icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-book'
          },
          {
            label: 'Veelgestelde vragen',
            items: [],
            routerLink: '/veelgestelde-vragen',
            icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-help-circle'
          },
          {
            label: 'Contact',
            items: [],
            routerLink: '/support',
            icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-message-square',
            noBorder: true
          },
          {
            header: 'Kennisbank',
            label: 'Begrippen',
            icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-book-open',
            routerLink: '/begrippen',
            items: []
          },
          {
            label: 'Woonplaatsen',
            icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-book-open',
            routerLink: '/woonplaatsen',
            items: []
          },
          {
            label: 'Kadastrale gemeenten',
            icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-book-open',
            routerLink: '/kadastrale-gemeenten',
            items: []
          },
          {
            label: 'Gemeenten',
            icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-book-open',
            routerLink: '/gemeenten',
            items: []
          },
          {
            label: 'Wijken',
            icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-book-open',
            routerLink: '/wijken',
            items: []
          },
          {
            label: 'Buurten',
            icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-book-open',
            routerLink: '/buurten',
            items: [],
            noBorder: true
          },
          {
            header: 'Overig',
            label: 'Nieuws',
            items: [],
            routerLink: '/nieuws',
            icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-mail'
          },
          {
            label: 'API',
            items: [],
            routerLink: '/upgrade/api',
            icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-repeat'
          },
          {
            label: 'Maatwerk',
            items: [],
            routerLink: '/maatwerk',
            icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-pen-tool'
          },
          {
            label: 'Over ons',
            items: [],
            routerLink: '/over-ons',
            icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-info',
            noBorder: true
          }
        ]
      },

    ];

    return menu;
  }

  private getExportUrl(hasAdvancedSearchPermission: boolean) {
    return hasAdvancedSearchPermission ? '/export' : '/upgrade/geavanceerd-zoeken';
  }

}
