import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, scan } from 'rxjs';
import { CoreModule } from '../core.module';
import { FileRef } from '../interfaces/file-ref';

export interface UploadState {
  progress: number
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE',
  response: HttpResponse<any>;
}

@Injectable({
  providedIn: CoreModule
})
export class FileService {
  private apiLocation = environment.apiDomain + environment.apiUrl;

  constructor(
    private http: HttpClient) { }

  public listOrganisationFiles(organisationId: string) {
    return this.http.get<FileRef[]>(`${this.apiLocation}/organisation/${organisationId}/file`);
  }

  public uploadFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<FileRef>(`${this.apiLocation}/file`, formData);
  }

  public linkFile(fileId: string, linkParams: {
    userParcelId?: string
  }) {
    return this.http.put<FileRef>(`${this.apiLocation}/file/${fileId}`, linkParams);
  }

  public getFileRefByOrderItemId(orderItemId: string) {
    return this.http.get<FileRef>(`${this.apiLocation}/order-item/${orderItemId}/file`);
  }

  public getDownloadUrl(fileId: string, token: string) {
    return `${this.apiLocation}/file/${fileId}/download?token=${token}`;
  }

  public download(fileId: string) {
    return this.http.get<FileRef>(`${this.apiLocation}/file/${fileId}/download`, {
      responseType: 'blob' as any
    });
  }

  public deleteFileRef(fileId: string) {
    return this.http.delete<void>(`${this.apiLocation}/file/${fileId}`);
  }

  public uploadFile2(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    return this.http.post<any>(`${this.apiLocation}/file`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      this.upload(),
    );
  }


  public upload(): (
    source: Observable<HttpEvent<unknown>>
  ) => Observable<UploadState> {
    const initialState: UploadState = { state: 'PENDING', progress: 0, response: null };

    const calculateUploadState = (upload: UploadState, event: HttpEvent<unknown>): UploadState => {
      if (event instanceof HttpErrorResponse) {
        throw event.error;
      }
      if (this.isHttpProgressEvent(event)) {
        return {
          progress: event.total
            ? Math.round((100 * event.loaded) / event.total)
            : upload.progress,
          state: 'IN_PROGRESS',
          response: null
        };
      }
      if (this.isHttpResponse(event)) {
        return {
          progress: 100,
          state: 'DONE',
          response: event as HttpResponse<FileRef>
        };
      }
      return upload;
    }

    return (source) => source.pipe(scan(calculateUploadState, initialState));
  }


  public isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response;
  }

  public isHttpProgressEvent(
    event: HttpEvent<unknown>
  ): event is HttpProgressEvent {
    return (
      event.type === HttpEventType.DownloadProgress ||
      event.type === HttpEventType.UploadProgress
    )
  }



}
