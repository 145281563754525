export const MIN_ZOOMLVL_BAGPAND_LAYER = 17;
export const MIN_ZOOMLVL_TRANSACTION_LAYER = 17;

export const LAYER_SETTINGS = [
  {
    name: 'luchtfoto',
    alias: 'Luchtfoto',
    active: true,
    canDisable: true,
    type: 'basemap',
    minZoom: null,
    variations: {
      available: [
        { name: '2016_ortho25', alias: '2016', requiredPermission: 'premium_data:historische_luchtfoto_layers:view' },
        { name: '2017_ortho25', alias: '2017', requiredPermission: 'premium_data:historische_luchtfoto_layers:view' },
        { name: '2018_ortho25', alias: '2018', requiredPermission: 'premium_data:historische_luchtfoto_layers:view' },
        { name: '2019_ortho25', alias: '2019', requiredPermission: 'premium_data:historische_luchtfoto_layers:view' },
        { name: '2020_ortho25', alias: '2020', requiredPermission: 'premium_data:historische_luchtfoto_layers:view' },
        { name: '2021_orthoHR', alias: '2021', requiredPermission: 'premium_data:historische_luchtfoto_layers:view' },
        { name: '2022_orthoHR', alias: '2022', requiredPermission: 'premium_data:historische_luchtfoto_layers:view' },
        { name: '2023_orthoHR', alias: '2023', requiredPermission: null },
        { name: '2024_quickortho25', alias: '2024', requiredPermission: 'premium_data:historische_luchtfoto_layers:view' }
      ],
      selected: '2023_orthoHR'
    },
    thumbnail: 'assets/icons/basemap-luchtfoto@2x.png',
    legend: null
  },
  {
    name: 'kaart',
    alias: 'Kaart',
    active: false,
    canDisable: true,
    type: 'basemap',
    minZoom: null,
    thumbnail: 'assets/icons/basemap-kaart@2x.png',
    legend: null
  },
  {
    name: 'satelliet',
    alias: 'Satelliet (hi-res)',
    active: false,
    canDisable: true,
    type: 'basemap',
    minZoom: null,
    thumbnail: 'assets/icons/basemap-luchtfoto@2x.png',
    legend: null
  },
  {
    name: 'bebouwing',
    alias: 'Bebouwing',
    active: false,
    canDisable: true,
    type: 'overlay',
    minZoom: MIN_ZOOMLVL_BAGPAND_LAYER,
    thumbnail: 'assets/icons/basemap-bebouwing@2x.png',
    legend: 'bebouwing'
  },
  {
    name: 'transacties',
    alias: 'Transacties',
    active: false,
    canDisable: true,
    type: 'overlay',
    minZoom: MIN_ZOOMLVL_TRANSACTION_LAYER,
    thumbnail: 'assets/icons/basemap-recent-verkocht@2x.jpg',
    legend: null
  },
  {
    name: 'kadastralekaart',
    alias: 'Percelen',
    canDisable: false,
    active: true,
    type: 'overlay',
    minZoom: 17,
    thumbnail: 'assets/icons/basemap-user-parcels@2x.png',
    variations: {
      available: [
        { name: 'standaard', alias: 'Status', legend: 'kadastralekaart-standaard', requiredPermission: null },
        { name: 'kwaliteit', alias: 'Grensnauwkeurigheid', legend: 'kadastralekaart-kwaliteit', requiredPermission: 'premium_data:grensnauwkeurigheid_layer:view' },
      ],
      selected: 'standaard'
    },
    legend: null
  },
  {
    name: 'mijn-percelen',
    alias: 'Mijn percelen',
    active: true,
    canDisable: true,
    type: 'overlay',
    minZoom: null,
    thumbnail: 'assets/icons/basemap-user-parcels@2x.png',
    legend: null
  },
  {
    name: 'eigenaarstype',
    alias: 'Eigenaarstype',
    active: false,
    canDisable: true,
    type: 'overlay',
    minZoom: MIN_ZOOMLVL_BAGPAND_LAYER,
    thumbnail: 'assets/icons/basemap-eigenaarstype@2x.png',
    variations: {
      available: [
        { name: 'eigenaarstype_2016', alias: '2016', legend: 'kadastralekaart-eigenaarstype', requiredPermission: 'premium_data:eigenaarstype_layer:view' },
        { name: 'eigenaarstype_2020', alias: '2020', legend: 'kadastralekaart-eigenaarstype', requiredPermission: 'premium_data:eigenaarstype_layer:view' },
      ],
      selected: '2020'
    },
  },
] as const;

export type LayerSetting = typeof LAYER_SETTINGS[number];
export type LayerSettingName = typeof LAYER_SETTINGS[number]['name'];