import { NgModule } from '@angular/core';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { DatePipe } from '@angular/common';

@NgModule({
  imports: [],
  providers: [
    provideHttpClient(
      withInterceptorsFromDi(),
      withFetch()
    ),
    DatePipe
  ]
})
export class CoreModule { }
