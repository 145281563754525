<div class="skeleton" [class.skeleton--animate]="animate()">
    @switch (type()) {
    @case ('text') {
    @for (line of linesArray(); track $index) {
    <div class="skeleton__line" [style.width]="width()" [style.height]="height()"></div>
    }
    }
    @case ('image') {
    <div class="skeleton__image" [style.width]="width()" [style.height]="height()"></div>
    }
    @case('menu-item') {
    @for (line of linesArray(); track $index) {
    <div class="skeleton__menu">
        <div class="skeleton__menu__icon"></div>
        <div class="skeleton__menu__label">
            <div class="skeleton__menu__label__main"></div>
            <div class="skeleton__menu__label__subtitle"></div>
        </div>
    </div>
    }
    }
    @case('paragraph') {
    <div class="skeleton__paragraph__title blur"></div>
    <div class="skeleton__paragraph__text blur"></div>
    <div class="skeleton__paragraph__text blur"></div>
    <div class="skeleton__paragraph__text blur"></div>
    <div class="skeleton__paragraph__image blur"></div>
    <div class="skeleton__paragraph__text blur"></div>
    }
    }


</div>