import { Injectable } from "@angular/core";
import { State, Selector, createSelector, Action, StateContext } from "@ngxs/store";
import { tap } from "rxjs";
import { Alert, CreateAlert, DeleteAlert, ListAlerts, NotificationStateModel } from "./models/notification.state.model";
import { AlertService } from "@app/core/services/alert.service";

@State<NotificationStateModel>({
    name: 'notification',
    defaults: {
      alerts: []
    }
  })
  @Injectable()
  export class NotificationState {
    // Return notifications.
    @Selector([NotificationState])
    static notifications(state: NotificationStateModel) {
      return state.alerts;
    }
  
    // Dynamic selector notation for returning only 1 notification
    static getNotificationById(notificationId: string) {
      return createSelector(
        [NotificationState.notifications],
        (notifications: Alert[]) => {
          const notification = notifications.find(notification => notification.id === notificationId);
          return notification;
        }
      );
    }
  
    constructor(private changeNotificationService: AlertService) { }
  
    @Action(ListAlerts)
    listNotifications(ctx: StateContext<NotificationStateModel>, action: ListAlerts) {
      return this.changeNotificationService.findAll().pipe(
        tap(notifications => {
          ctx.setState({ alerts: notifications });
        })
      )
    }
  
    @Action(CreateAlert)
    createNotification(ctx: StateContext<NotificationStateModel>, action: CreateAlert) {
      return this.changeNotificationService.create(action.payload).pipe(
        tap(createdNotification => {
          const notifications = ctx.getState().alerts;
          ctx.patchState({ alerts: [...notifications, createdNotification] });
        })
      );
    }
  
    @Action(DeleteAlert)
    deleteNotification(ctx: StateContext<NotificationStateModel>, action: DeleteAlert) {
      return this.changeNotificationService.remove(action.payload.id).pipe(
        tap(() => {
          const notifications = ctx.getState().alerts;
          const filteredNotifications = notifications.filter(notification => notification.id !== action.payload.id);
          ctx.patchState({ alerts: filteredNotifications });
        })
      );
    }
  }