import { PropertyDescription } from '@app/core/config/user-properties';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Profile, User } from '../interfaces/user.model';
import { UserParcel } from '../interfaces/user-parcel.model';
import { environment } from 'environments/environment';
import { Order } from '../interfaces/order.model';
import { BasicMollieSubscription } from '../interfaces/types';
import { CoreModule } from '@app/core/core.module';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { ApiKey } from '../interfaces/api-key.model';

@Injectable({
  providedIn: CoreModule
})
export class UserService {
  private apiLocation = environment.apiDomain + environment.apiUrl;

  constructor(private http: HttpClient) {}


  public get(auth0Id: string) {
    return this.http.get<User>(this.apiLocation + `/user/${auth0Id}`);
  }

  public update(auth0Id: string, user: Partial<User>) {
    return this.http.put<User>(this.apiLocation + `/user/${auth0Id}`, user);
  }

  public delete(auth0Id: string) {
    return this.http.delete<void>(this.apiLocation + `/user/${auth0Id}`);
  }


  public adminUpdate(auth0Id: string, user: User) {
    return this.http.put<User>(this.apiLocation + `/admin/user/${auth0Id}`, user);
  }


  public list(params: {q: string | null | undefined, subscriptionType?: string | null | undefined}): Observable<User[]> {
    return this.http.get<User[]>(this.apiLocation + '/admin/user', {
      params
    });
  }

  public getAdminUser(auth0Id: string) {
    return this.http.get<User & { auth0User: Profile, userParcels: UserParcel[], orders: Order[], notifications: any[], activityData: {lastLoginDate: Date}}>(this.apiLocation + `/admin/user/${auth0Id}`)
  }

  public listOrders(auth0Id: string) {
    return this.http.get<Order[]>(`${this.apiLocation}/admin/user/${auth0Id}/order`);
  }

  public createActiveSubscription(auth0Id: string, subscriptionTypeId: string) {
    return this.http.post(this.apiLocation + `/admin/user/${auth0Id}/subscription/status/active`, {
      subscriptionTypeId: subscriptionTypeId
    });
  }

  public cancelSubscription(subscriptionId: string, directCancel = false) {
    const queryParams = { directCancel: JSON.stringify(directCancel) };
    return this.http.delete(this.apiLocation + `/admin/subscription/${subscriptionId}`, {
      params: queryParams
    });
  }

  public requestMandate(subscriptionTypeId: string, paymentTermName: string) {
    return this.http.post<any>(this.apiLocation + '/payment/verification', {
      subscriptionTypeId: subscriptionTypeId,
      paymentTermName: paymentTermName
    });
  }

  public getMollieSubscriptions(auth0Id: string) {
    return this.http.get<BasicMollieSubscription[]>(this.apiLocation + `/admin/user/${auth0Id}/mollie-subscription`);
  }

  public listApiKeys(q: string) {
    return this.http.get<ApiKey[]>(this.apiLocation + '/admin/api-key', {
      params: {
        q
      }
    });
  }

  public getDataStatistics() {
    return this.http.get(this.apiLocation + `/admin/data-statistics`);
  }
  
  public downloadUserParcelSpreadSheet(userParcelArray: UserParcel[], userProperties: PropertyDescription[]) {
    const csvData = this.convertUserParcelsToCSV(userParcelArray, userProperties);
    const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    const filename = 'opgeslagen-percelen-KadastraleKaart.com';
    saveAs(blob, `${filename}.csv`);
  }

  private convertUserParcelsToCSV(userParcelArray: UserParcel[], userProperties: PropertyDescription[]) {
    const headerList = [...userProperties];
    let csvBody = 'sep=;' + '\r\n';
    let row = '';
    row += headerList.map(header => header.alias).join(';');
    csvBody += row + '\r\n';

    userParcelArray.forEach(userParcel => {
      const line = headerList.map(header => {
        const flattenedParcel = {
          ...userParcel,
          ...userParcel.brkParcel,
          ...userParcel.userProperties
        };
        const userParcelProperty = flattenedParcel[header.name] || '';
        return `${userParcelProperty}`.replace(/(\r\n|\n|\r)/gm, ' ').replace(/[;]+/g, ':'); // Remove all line breaks from description; replace all semicolons with ':'
      });
      csvBody += line.join(';') + '\r\n';
    });
    return csvBody;
  }
}
