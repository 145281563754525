import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { CoreModule } from '@app/core/core.module';
import { Store } from '@ngxs/store';
import { AuthState } from '../states/auth.state';
import { catchError, Subject, switchMap, throwError } from 'rxjs';
import { GetNewAccessToken } from '../states/models/auth.state.model';
import { Router } from '@angular/router';

@Injectable({
    providedIn: CoreModule
})
export class AuthInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshToken$: Subject<string> = new Subject<string>();

    constructor(private store: Store, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        // Add tokens if requests are made to our own backend.
        const isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);
        const isOurOwnApi = request.url.includes(environment.apiDomain);
        const isNotAuthRequest = request.url.includes('/auth/') === false;
        if (isAuthenticated && isOurOwnApi && isNotAuthRequest) {

            const accessToken = this.store.selectSnapshot(AuthState.accessToken);

            const tokenReq = this.addToken(request, accessToken);

            return next.handle(tokenReq).pipe(
                // Catch 401 errors and try to get a new access token.
                catchError((error) => {
                    // Check if the error is due to an expired access token
                    if (error.status === 401 && accessToken) {
                        return this.handleTokenExpired(request, next);
                    }

                    return throwError(error);
                })
            );
        } else {
            return next.handle(request);
        }
    }

    private handleTokenExpired(request: HttpRequest<any>, next: HttpHandler) {
        if (this.isRefreshing === true) {
            // If already refreshing, wait for the new token before retrying the request
            return this.refreshToken$.pipe(
                switchMap(() => {
                    return next.handle(this.addToken(request, this.store.selectSnapshot(AuthState.accessToken)));
                })
            );
        }

        // Call the refresh token endpoint to get a new access token
        const refreshToken = this.store.selectSnapshot(AuthState.refreshToken);

        this.isRefreshing = true;

        return this.store.dispatch(new GetNewAccessToken({ refreshToken: refreshToken })).pipe(
            switchMap(() => {
                // Retry the original request with the new access token
                const newAccessToken = this.store.selectSnapshot(AuthState.accessToken);
                this.isRefreshing = false;
                this.refreshToken$.next(newAccessToken);
                return next.handle(this.addToken(request, newAccessToken));
            }),
            catchError((error) => {
                // Handle refresh token error (e.g., redirect to login page)
                console.error('Error handling expired access token:', error);
                this.isRefreshing = false;
                this.router.navigate(['/account/uitloggen'])

                return throwError(error);
            })
        );
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
}
