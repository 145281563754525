
/**
 * Represents the authentication state model.
 * @remarks
 * Consider adding the following properties for a more comprehensive model:
 * - `expiresIn: number` - The duration in seconds for which the access token is valid.
 * - `tokenType: string` - The type of the token, typically "Bearer".
 * - `userId: string` - The identifier of the authenticated user.
 * - `roles: string[]` - The roles or permissions associated with the authenticated user.
 */
export interface AuthStateModel {
    user_id: string,
    organisation_id: string,
    roles: { role_name: string, id: number }[];
    permissions: string[];
}

export class LoginWithGoogle {
    static readonly type = '[App] LoginWithGoogle';
    constructor(public payload: any) { }
}

export class Login {
    static readonly type = '[App] Login';
    constructor(public payload: { email: string, password: string }) { }
}

export class Logout {
    static readonly type = '[Auth] Logout';
    constructor() { }
}

export class GetNewAccessToken {
    static readonly type = '[Auth] GetNewAccessToken';
    constructor(public payload: { refreshToken: string }) { }
}