import { UserParcel } from "@app/core/interfaces/user-parcel.model";

export interface GetUserParcelParams {
    organisation_id: string,
    limit: string,
    offset: string,
    sortBy?: string,
    tags?: string[],
    searchTerm?: string,
    minSize?: string,
    maxSize?: string,
    bbox?: string
}

export class UserParcelsStateModel {
    userParcels: UserParcel[];
    tags: string[]
}

export class ListUserParcels {
    static readonly type = '[UserParcels] List';
    constructor(public payload: GetUserParcelParams) {
    }
}

export class CreateUserParcels {
    static readonly type = '[UserParcels] Create';
    constructor(public payload: { parcels: Partial<UserParcel>[], organisationId: string }) {
    }
}


export class RemoveUserParcel {
    static readonly type = '[UserParcels] Remove';
    constructor(public payload: { id: string }) {
    }
}

export class UpdateUserParcel {
    static readonly type = '[UserParcels] Update';
    constructor(public payload: { id: string, parcel: UserParcel }) {
    }
}


export class AppendUserParcels {
    static readonly type = '[UserParcels] List and append';
    constructor(public payload: GetUserParcelParams) {
    }
}

export class ListTags {
    static readonly type = '[UserParcels] ListTags';
    constructor(public payload: {
        organisationId: string
    }) {
    }
}



export class ClearUserParcels {
    static readonly type = '[UserParcels] Clear';
    constructor() {
    }
}

