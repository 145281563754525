/* Colors: 
#e41a1c
#377eb8
#4daf4a
#984ea3
#ff7f00
#ffff33
#a65628
*/
export const CHANGE_TYPES = [
    {
        category: 'bekendmaking vergunningsaanvraag',
        categoryAlias: 'Bekendmakingen',
        types: [
            'omgevingsvergunning',
            'evenementenvergunning',
            'exploitatievergunning',
            'melding',
            'andere beschikking',
            'vergunning voor activiteiten oppervlaktewater'
        ],
        color: '#e41a1c'
    },
    {
        category: 'verblijfsobject',
        categoryAlias: 'Adreswijzigingen',
        types: [
            'verblijfsobject in verbouwing',
            'bouwvergunning verleend',
            'nieuw verblijfsobject gevormd'
        ],
        color: '#377eb8'
    },
    {
        category: 'percelen',
        categoryAlias: 'Percelen',
        types: [
            'nieuwe publiekrechtelijke beperking',
            'wijziging perceelsgrens'
        ],
        color: '#4daf4a'
    }
];



