import { LatLngBoundsLiteral } from "leaflet";
import { ChangeAttributes } from "shared-types";

export interface ChangeStateModel {
    highlighted: Array<string>;
    changes: (ChangeAttributes & { color: string })[];
}

export class ListChanges {
    static readonly type = '[Change] List';
    constructor(public payload: { bounds: LatLngBoundsLiteral, dateRange: { fromDate: Date, toDate: Date }, types: string[] }) { }
}

export class SetChangeHighlight {
    static readonly type = '[Change] SetHighlight';
    constructor(public payload: { ids: string[] }) { }
}
